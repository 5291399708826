import { MANAGEMENT_MENU } from '@/config/constants';

/**
 * @param {StoreState} state
 * @param {import('vuex').Store<StoreState>} store
 * @return Array<MenuItem>
 */
export function withAccessMenu(state, store) {
  try {
    return withAccessMenuUnsafe(state, store);
  } catch (e) {
    console.error('error in withAccessMenuUnsafe', e);
    return [];
  }
}

const DEFAULT_MENU_ITEMS = MANAGEMENT_MENU.filter(e => !e.permissions && e.roles?.includes('user') && !e.tppOnly && e.title !== 'Home');

/**
 * @param {StoreState} state
 * @param {import('vuex').Store<StoreState>} store
 * @return Array<MenuItem>
 */
function withAccessMenuUnsafe(state, store) {

  if (typeof state.permissions === 'string')
    return DEFAULT_MENU_ITEMS;

  const user = state.user;
  if (!user)
    return DEFAULT_MENU_ITEMS;

  // these have .value to mirror previous implementation with ref/computed
  const permissions = { value: state.permissions };
  const email = { value: user.email };
  const hasPermissions = { value: store.getters.merchantHasPermissions };
  const isCertification = { value: store.getters.isCertificationClient };
  const role = { value: store.getters.role };

  /**
   * @type {import('../../../config/constants.js').MenuItem[]}
   */
  const menuItems = MANAGEMENT_MENU;

  if (permissions.value === 'FETCHING') {
    return DEFAULT_MENU_ITEMS;
  }

  // what a user can do on any account
  // if they can do it somewhere, they need the menu item

  let memberships = user.account.memberships
  let membershipsWithRole = memberships.filter(m => !!m.role?.id)

  /*
  // if no memberships with role and have user role, use that instead
  // this means less visibility into why things work, but less things broken
  // no more user.role on FE!!!

  if (!membershipsWithRole || !membershipsWithRole.length && user.role) {
    membershipsWithRole = [{
      id: user.id,
      merchant: user.account.default_client.merchant,
      role: user.role
    }]
  }
  */

  // if (!membershipsWithRole || !membershipsWithRole.length) return [];
  const userPerms = store.getters.grantedPermissions;

  // noinspection UnnecessaryLocalVariableJS
  let filteredMenuItems = menuItems.filter((menuItem) => {

    // enable some features for partners
    if (menuItem.tppAndPartnersOnly === true) {
      try {
        return Boolean(email.value.endsWith('@tripleplaypay.com') || email.value.endsWith('@nxt-moves.io') || email.value.endsWith('@clearpaymail.com'));
      } catch (err) {
        console.error('Error occurred while checking user email:', err);
        return false;
      }
    }

    if (menuItem.tppOnly === true) {
      try {
        return Boolean(email.value.endsWith('@tripleplaypay.com'));
      } catch (err) {
        console.error('Error occurred while checking user email:', err);
        return false;
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (menuItem.hasOwnProperty('parentsOnly') && menuItem.parentsOnly === true) {
      try {
        return Boolean(user.is_parent_member === true)
      } catch (err) {
        console.error('Error occurred while checking if user is part of a parent:', err);
        return false;
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (menuItem.hasOwnProperty('isCertificationClient') && menuItem.isCertificationClient === true) {
      try {
        return Boolean(isCertification)
      } catch (err) {
        console.error('Error occurred while checking if user is by default associated to a certification client:', err);
        return false;
      }
    }

    // if (menuItem.title === "Mimic") {
    //   // Check if user has PERMISSIONS object before rendering
    // }

    if (!menuItem.roles && !menuItem.permissions) {
      console.log('filteredMenuItems', {
        'Menu item permissions:': menuItem.permissions,
        outcome: true
      });
      return true;
    } else if (hasPermissions.value && userPerms && menuItem.permissions) {
      let outcome = menuItem.permissions.some((mIP) =>
        userPerms.includes(mIP)
      );
      console.log('filteredMenuItems', {
        'User role:': user.account.memberships,
        'Granted permissions:': userPerms,
        'Menu item permissions:': menuItem.permissions,
        outcome
      });
      return outcome;
    }

    return menuItem.roles.includes(role.value);
  });

  // feature flags
  let skip = store.getters.merchantSettings.skipped_menu_titles;
  if (Array.isArray(skip)) {
    filteredMenuItems = filteredMenuItems.filter(e => !skip.includes(e.title));
  }

  return filteredMenuItems;
}
